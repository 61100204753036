.navList {
  margin-top: -2px;
  margin-bottom: var(--space-2x);
}

.navList .listItem {
  flex-grow: 1;
}

.navList .listItem > a {
  width: 150px;
}

@media screen and (min-width: 1025px) {
  .navList {
    justify-content: center;
    border-bottom: 1px solid var(--border-gray-light);
    margin-bottom: unset;
  }

  .navList .listItem {
    flex-grow: 0;
  }

  .navList .listItem > a {
    margin-top: 4px;
    font-size: var(--fontSize-lg);
  }
  .navList .listItem > a::before {
    bottom: 0px;
  }
}
