.link {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width: 100%;
  height: 90px;
  margin-top: var(--space-2x);
  margin-bottom: var(--space-6x);
  border-radius: var(--space-2x);
  overflow: hidden;
}

.bgImage {
  z-index: -1;
  position: absolute;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .link {
    flex-direction: column;
    height: 90px;
    gap: var(--space);
  }
}
