.mv {
  position: relative;
}

.main {
  padding-top: var(--space-16x);
  padding-bottom: var(--space-16x);
}

.main > :last-child {
  padding-top: var(--space-8x);
}

.catchphraseForSp {
  display: none;
}

@media screen and (min-width: 1025px) {
  .searchBox {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 50;
    border: 2px solid var(--border-gray-light);
    border-radius: var(--rounded-md);
    background: var(--bg-white);
  }

  .searchBoxInner {
    border-radius: var(--rounded-md);
    box-shadow: unset;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    padding-top: var(--space-4x);
    padding-bottom: var(--space-6x);
  }
  .searchBox {
    position: relative;
    margin-top: calc(var(--space-4x) * -1);
    padding: var(--space-4x) 0 0 0;
    border-radius: var(--space-4x) var(--space-4x) 0 0;
    background-color: var(--bg-white);
  }
  .catchphraseForSp {
    display: block;
    margin-top: 16px;
  }
}
