.link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-20x);
  background-image: url(https://newt.imgix.net/product/signup_background.png);
  background-size: cover;
  width: 100%;
  height: 90px;
  margin-top: var(--space-2x);
  margin-bottom: var(--space-6x);
  border-radius: var(--space-2x);
}

.link > img {
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
  .link {
    flex-direction: column;
    height: 110px;
    gap: var(--space-3x);
  }
}
