.root {
  padding-top: var(--space-8x);
  padding-bottom: var(--space-12x);
}
.root section {
  padding-top: var(--space-6x);
  padding-bottom: var(--space-6x);
}

.item img {
  border-radius: var(--rounded);
}

.items li:not(:first-child) {
  margin-left: var(--space-6x);
}

.destinationName {
  margin-top: var(--space-2x);
  width: 108px;
}

.items button {
  top: 42%;
}

@media screen and (max-width: 1024px) {
  .root {
    padding-bottom: var(--space-8x);
  }

  .items {
    margin-inline: calc(var(--space-4x) * -1);
  }

  .items li:first-child {
    padding-left: var(--space-4x);
  }

  .items li:last-child {
    padding-right: var(--space-4x);
  }

  .items li:not(:first-child) {
    margin-left: var(--space-4x);
  }

  .destinationName {
    width: 96px;
  }
}
